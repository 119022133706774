export const menuData = ({
  openProfile,
  setOpenProfile,
  openGovernance,
  setOpenGovernance,
  openSustainability,
  setOpenSustainability,
}) => ({ t, SubMenuItem }) => {
  return [
    {
      exact: false,
      to: { pathname: process.env.REACT_APP_GROUP_URL },
      labelLink: t('menu.group'),
      target: '_parent',
    },
    {
      exact: true,
      to: '/',
      labelLink: 'Home',
    },
    {
      exact: false,
      to: '/profile',
      openCloseValue: openProfile,
      openCloseCallback: setOpenProfile,
      labelLink: t('menu.profile'),
      subMenu: (
        <SubMenuItem
          title={t('menu.profile')}
          closeCallback={setOpenProfile}
          subMenuList={[
            { title: t('profile.who-we-are'), 
              linkTo: '/profile/who-we-are',
              closeCallback: {setOpenProfile},
            },
            {
              title: t('profile.our-history'),
              linkTo: '/profile/our-history',
              closeCallback: {setOpenProfile},
            },
            {
              title: t('profile.business-areas'),
              linkTo: '/profile/business-areas',
              closeCallback: {setOpenProfile},
            },
            {
              title: t('profile.our-strength'),
              linkTo: '/profile/our-strength',
              closeCallback: {setOpenProfile},
            },
            {
              title: t('profile.plant'),
              linkTo: '/profile/plant',
              closeCallback: {setOpenProfile},
            },
            {
              title: t('profile.brochure'),
              linkTo: '/profile/brochure',
              closeCallback: {setOpenProfile},
            },
          ]}
        />
      ),
    },
    {
      exact: false,
      to: '/projects',
      labelLink: t('menu.projects'),
    },
    {
      exact: false,
      to: '/governance',
      openCloseValue: openGovernance,
      openCloseCallback: setOpenGovernance,
      labelLink: t('menu.governance'),
      subMenu: (
        <SubMenuItem
          title={t('menu.governance')}
          closeCallback={setOpenGovernance}
          subMenuList={[
            {
              title: t('governance.management'),
              linkTo: '/governance/management',
              closeCallback: {setOpenGovernance},
            },
            {
              title: t('governance.ethics'),
              linkTo: '/governance/ethics',
              closeCallback: {setOpenGovernance},
            },
            {
              title: t('governance.anti-corruption'),
              linkTo: '/governance/anti-corruption',
              closeCallback: {setOpenGovernance},
            },
            {
              title: t('governance.whistle-blowing'),
              linkTo: '/governance/whistle-blowing',
              closeCallback: {setOpenGovernance},
            },
          ]}
        />
      ),
    },
    {
      exact: false,
      to: '/sustainability',
      openCloseValue: openSustainability,
      openCloseCallback: setOpenSustainability,
      labelLink: t('menu.sustainability'),
      subMenu: (
        <SubMenuItem
          title={t('menu.sustainability')}
          closeCallback={setOpenSustainability}
          subMenuList={[
            {
              title: t('sustainability.commitment-and-sustainability'),
              linkTo: '/sustainability/commitment-and-sustainability',
              closeCallback: {setOpenSustainability},
            },
            {
              title: t('sustainability.certifications'),
              linkTo: '/sustainability/certifications',
              closeCallback: {setOpenSustainability},
            },
            {
              title: t('sustainability.sa-8000-reports'),
              linkTo: '/sustainability/sa-8000-reports',
              closeCallback: {setOpenSustainability},
            },
          ]}
        />
      ),
    },
    {
      exact: false,
      to: '/contacts',
      labelLink: t('menu.contacts'),
    },
    {
      exact: false,
      to: '/work-with-us',
      labelLink: t('menu.work-with-us'),
    },
    {
      exact: false,
      to: '/suppliers',
      labelLink: t('menu.suppliers'),
    },
  ];
};
