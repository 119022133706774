export const siteMapData = ({ t }) => [
  {
    name: 'Fincantieri Infrastructure S.p.A.',
    office1: `<p style="margin-bottom:0px">Sede Legale: Via Genova 1 - 34121 Trieste - Italy</p>`,
    office2: `Sede Operativa: Via del Lavoro, 1 - 37067 Valeggio sul Mincio (VR) - Italy`,
    register:'', 
    legal: `
    Registro delle Imprese Venezia Giulia<br />
    C.F. e n. iscr.  01294560329<br />
    P.IVA 01294560329<br />
    N. R.E.A.   TS - 200879<br />`,
    cap: `Capitale Sociale € 500.000,00`,
    soc: `Società soggetta a direzione e coordinamento di Fincantieri  S.p.A.<br />
    Società con socio unico `,
    contacts: `
    CONTATTI<br />
    <a href="mailto:info@fincantieri-infrastructure.it">info@fincantieri-infrastructure.it</a><br />
    <a href="mailto:fincantieri-infrastructure@pec.fincantieri.it">fincantieri-infrastructure@pec.fincantieri.it</a><br />
    <a href="https://www.fincantieri-infrastructure.com">www.fincantieri-infrastructure.com</a>
    `,
  },
  {

  },
];
